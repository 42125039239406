



























import Vue from 'vue';
export default Vue.extend({
  name: 'PlayerView',
  data() {
    return {
      navLocation: 'my-hero-academia/izuku-midoriya-origin/',
    };
  },
  computed: {
    validRoute(): boolean {
      const { showSlug, episodeSlug } = this.$route.params;
      return showSlug && episodeSlug;
    },
    episodeName(): string {
      return this.$store.getters['videoPlayerCatalog/currentEpisodeName'] || '';
    },
    synopsis(): string {
      return this.$store.getters['videoPlayerCatalog/currentEpisodeSynopsis'] || '';
    },
    episodeThumbnail(): string {
      const imagePath =  this.$store.getters['videoPlayerCatalog/currentEpisodeShowThumbnail'];
      if (!imagePath) {
        return 'https://static.funimation.com/static/img/funimation-share-logo.jpg';
      }

      let resizeString = '';
      resizeString = 'w_400,';
      resizeString += 'q_60,';
      resizeString += 'c_fill';
      return imagePath.replace('upload/', `upload/${resizeString}/`);
    },
  },
  methods: {
    navigateToMha(): void {
      (window as any).location = this.navLocation;
    },
  },
  metaInfo(): any {
    const { showSlug, episodeSlug } = this.$route.params;
    return {
      title: `${this.episodeName} | Watch on Funimation`,
      link: [
        {
          rel: 'canonical',
          href: `https://www.funimation.com/v/${showSlug}/${episodeSlug}`,
        },
      ],
      meta: [
        {
          vmid: 'og:title',
          name: 'og:title',
          content: `${this.episodeName} | Watch on Funimation`,
        },
        {
          vmid: 'description',
          name: 'description',
          content: this.synopsis,
        },
        {
          vmid: 'og:site_name',
          name: 'og:site_name',
          content: 'Funimation',
        },
        {
          vmid: 'og:description',
          name: 'og:description',
          content: this.synopsis,
        },
        {
          vmid: 'og:type',
          name: 'og:type',
          content: 'website',
        },
        {
          vmid: 'og:image',
          name: 'og:image',
          // TODO: Update from ShowDetails
          content: this.episodeThumbnail,
        },
        {
          vmid: 'og:locale',
          name: 'og:locale',
          content: 'en_US',
        },
        {
          vmid: 'og:locale:alternate',
          name: 'og:locale:alternate',
          content: 'en_AU',
        },
        // TODO: Fork vue-meta to allow for multiple og:locale:alternate tags as only the first one declared will be added to the page
        {
          vmid: 'og:locale:alternate',
          name: 'og:locale:alternate',
          content: 'en_NZ',
        },
        {
          vmid: 'fb:app_id',
          name: 'fb:app_id',
          content: '444514955756615',
        },
      ],
    };
  },
});
